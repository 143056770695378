<template>
    <div class="outlets container">
        <div>
            <div class="ui simple middle aligned computer only tablet only two column grid">
                <div class="column">
                    <h3 class="ui header">
                        Outlets
                        <div class="sub header">Manage system outlets here</div>
                    </h3>
                </div>
                <div class="right aligned column">
                    <button class="ui black small button" type="button" @click="addOutlet"><i class="fontello-plus icon"></i> New Outlet</button>
                </div>
            </div>
            <div class="ui simple middle aligned mobile only grid">
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="!mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div>
                                    <h3 class="ui header">
                                        Outlets
                                        <div class="sub header">Manage system outlets here</div>
                                    </h3>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="plus icon link" @click="addOutlet">
                                        <span>&plus;</span>
                                    </a>
                                    <a href="javascript:void(0);" class="icon link" @click="mobile_search = true;">
                                        <i class="search icon"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div style="flex: 1; min-width: 0;">
                                    <form class="ui search form" @submit.prevent="search_">
                                        <div class="ui icon fluid input" :class="{loading: loading}">
                                            <input type="text" placeholder="Name..." v-model.trim.lazy="query"/>
                                            <i class="search link icon"></i>
                                        </div>
                                    </form>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="close icon link" @click="mobile_search = false;">
                                        &times;
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="ui hidden divider"></div>
        <div>
            <div class="ui two column stackable simple grid">
                <div class="tablet only computer only column">
                    <form class="ui form" @submit.prevent="search_">
                        <div class="ui left icon action fluid input" :class="{loading: loading}">
                            <i class="search icon"></i>
                            <input type="text" placeholder="Name..." v-model.trim.lazy="query"/>
                            <button type="submit" class="ui icon black button">
                                <i class="arrow right icon"></i>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="right aligned column">
                    <div class="ui compact secondary menu">
                        <div ref="sortbydropdown" class="ui inline dropdown item">
                            <input type="hidden" :value="sorting.by" @change="changeSorting"/>
                            <span style="margin-right: 0.5em;">Sort By:</span><div class="text">Default</div>
                            <i class="dropdown icon"></i>
                            <div class="menu">
                                <div class="item" data-value="name">Name</div>
                            </div>
                        </div>
                        <button class="ui fitted item button" type="button" title="Ascending" @click="sortAsc"><i class="sort amount up icon" :class="{teal: sorting.order === 'asc'}"></i></button>
                        <button class="ui fitted item button" type="button" title="Descending" @click="sortDesc"><i class="sort amount down icon" :class="{teal: sorting.order === 'desc'}"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-container">
            <div class="ui hidden divider"></div>
            <div class="ui very basic segment" :class="{loading}">
                <table class="ui very basic outlet middle aligned unstackable responsive table">
                    <thead>
                        <tr>
                            <th>Outlet</th>
                            <th>Email</th>
                            <th>Contact No.</th>
                            <th></th>
                        </tr>
                    </thead>
                    <template v-if="outlets.length">
                        <transition-group tag="tbody" name="vue-server-side-paginated-list" mode="out-in">
                            <tr v-for="outlet in outlets" :key="`outlet_tr_${outlet.id}`">
                                <td>
                                    <strong>{{outlet.name}}</strong>
                                    <div class="meta">
                                        <span class="location">{{outlet.location}}</span>
                                    </div>
                                </td>
                                <td>
                                    <a :href="`mailto:${outlet.email}`">{{outlet.email}}</a>
                                </td>
                                <td>
                                    <a :href="`tel:${outlet.contact_no}`">{{outlet.contact_no}}</a>
                                </td>
                                <td class="right aligned">
                                    <div class="ui text compact action menu">
                                        <div class="ui right dropdown fitted item">
                                            <i class="ellipsis vertical teal icon"></i>
                                            <div class="menu">
                                                <a class="item" href="javascript:void(0);" @click="editOutlet(outlet)">Edit Outlet</a>
                                                <a class="item" href="javascript:void(0);" @click="deleteOutlet(outlet)"><span class="ui red text">Delete Outlet</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </transition-group>
                    </template>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">No outlet found.</td>
                        </tr>
                    </tbody>
                    <tfoot v-show="outlets.length">
                        <tr>
                            <th colspan="4" class="right aligned">
                                <pagination-menu ref="paginationmenu" :total-count="pagination.total_count" :start-page="pagination.page" :page-size="pagination.size" @page-changed="changePage" always-show/>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <div ref="outletmodal" class="ui outlet modal">
            <div class="content">
                <h3 class="ui header">{{outlet_form.id? "Update Outlet" : "Create New Outlet"}}</h3>
                <div class="ui hidden divider"></div>
                <form :id="`outletform${_uid}`" class="ui form" :class="{loading: saving}" @submit.prevent="saveOutlet">
                    <div class="two column fields">
                        <div class="required field">
                            <label>Name</label>
                            <input type="text" v-model.trim="outlet_form.name" required/>
                        </div>
                    </div>

                    <div class="two column fields">
                        <div class="field">
                            <label>Email</label>
                            <input type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$" v-model.trim="outlet_form.email" v-lowercase/>
                        </div>
                        <div class="field">
                            <label>Contact Number</label>
                            <input type="tel" v-model.trim="outlet_form.contact_no"/>
                        </div>
                    </div>

                    <div class="field">
                        <label>Location</label>
                        <textarea v-model.trim="outlet_form.location"></textarea>
                    </div>
                </form>
            </div>
            <div class="actions">
                <button type="submit" :form="`outletform${_uid}`" class="ui submit teal button" :class="{loading: saving}" :disabled="saving">{{outlet_form.id? "Update" : "Submit"}}</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from "lodash.debounce";

import PaginationMenu from "@/components/PaginationMenu";

export default {
    name: "outlets",
    components: {
        PaginationMenu,
    },
    data() {
        return {
            mobile_search: false,
            loading: true,
            saving: false,
            query: "",
            outlets: [],
            outlet_form: {},
            sorting: {
                by: "created",
                order: "desc"
            },
            pagination: {
                total_count: 0,
                page: 1,
                size: 10
            },
            opened_modals: [],
            history_popped: false,
            scrollY: window.scrollY || 0,
            search_: debounce(this.search, 100)
        };
    },
    created() {
        this.search_();
    },
    mounted() {
        window.addEventListener("popstate", this.popstateHandler);

        $(this.$refs.sortbydropdown).dropdown();
        $(this.$refs.outletmodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "outletmodal_opened");
                this.opened_modals.push("outletmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "outletmodal";
                });
            }
        });
    },
    activated() {
        this.search().finally(() => {
            if(this.scrollY) {
                this.$nextTick(() => {
                    window.scroll(0, this.scrollY);
                });
            };
        });
    },
    updated() {
        $(this.$el).find(".ui.action.menu:not(.disabled) .ui.dropdown").dropdown();
    },
    beforeRouteLeave(to, from, next) {
        this.scrollY = window.scrollY;
        next();
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.popstateHandler);
    },
    computed: {
        search_params() {
            let search_params = {
                page: this.pagination.page,
                size: this.pagination.size,
                sort: this.sorting.by,
                order: this.sorting.order
            };

            if(this.query) {
                search_params.search = JSON.stringify({
                    name: this.query
                });
            }

            return search_params;
        }
    },
    methods: {
        popstateHandler(event) {
            this.history_popped = true;
            this.opened_modals.forEach((modal) => {
                $(this.$refs[modal]).modal("hide");
            });

            this.history_popped = false;
        },
        addOutlet() {
            this.outlet_form = {};
            $(this.$refs.outletmodal).modal("show");
        },
        saveOutlet() {
            if(this.saving) {
                return;
            }

            this.saving = true;

            let outlet = this.outlet_form;
            if(outlet.id) {
                this.$http.put(`outlets/${outlet.id}`, outlet).then((response) => {
                    this.$toasted.success("Outlet updated successfully.", {
                        duration: 3000
                    });

                    this.search_();
                    $(this.$refs.outletmodal).modal("hide");
                }).catch((error) => {
                    console.error(error);

                    this.$toasted.error("Failed to update outlet.", {
                        duration: 3000
                    });
                }).finally(() => {
                    this.saving = false;
                });
            } else {
                this.$http.post("outlets", outlet).then((response) => {
                    this.$toasted.success("Outlet created successfully.", {
                        duration: 3000
                    });

                    this.search_();
                    $(this.$refs.outletmodal).modal("hide");
                }).catch((error) => {
                    console.error(error);

                    this.$toasted.error("Failed to create outlet.", {
                        duration: 3000
                    });
                }).finally(() => {
                    this.saving = false;
                });
            }
        },
        search() {
            this.loading = true;

            const params = this.search_params;
            return this.$http.get("outlets", {
                params
            }).then((response) => {
                this.outlets = response.data;

                const total_count = response.headers["x-total-count"];
                this.pagination.total_count = total_count? +total_count : this.outlets.length;
            }).catch((error) => {
                console.error(error);

                this.$toasted.error("Unable to list outlets. Please try again later.", {
                    duration: 3000
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        changeSorting(event) {
            this.sorting.by = event.target.value;
        },
        sortAsc() {
            this.sorting.order = "asc";
        },
        sortDesc() {
            this.sorting.order = "desc";
        },
        changePage(page) {
            this.pagination.page = page;
        },
        editOutlet(outlet) {
            this.outlet_form = Object.assign({}, outlet);
            $(this.$refs.outletmodal).modal("show");
        },
        deleteOutlet(outlet) {
            this.$confirm({
                title: "Delete User",
                message: `Are you sure you want to delete outlet <strong>${outlet.name}</strong>?`,
                button: {
                    yes: "Yes",
                    no: "Cancel"
                },
                callback: (confirmed) => {
                    if(confirmed) {
                        this.$http.delete(`outlets/${outlet.id}`).then((response) => {
                            this.$toasted.success(`Outlet ${outlet.name} deleted successfully.`, {
                                duration: 3000
                            });

                            this.search_();
                        }).catch((error) => {
                            console.error(error);

                            this.$toasted.error(`Failed to delete outlet ${outlet.name}.`, {
                                duration: 3000
                            });
                        });
                    }
                }
            });
        }
    },
    watch: {
        search_params: {
            deep: true,
            handler(search_params, _search_params) {
                if(search_params.search !== _search_params.search) {
                    if(this.pagination.page === 1) {
                        this.search_();
                    } else {
                        this.$refs.paginationmenu.stepTo(1);
                    }
                } else {
                    this.search_();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.outlets.container {
    background: #fff;
}

.meta {
    margin-top: 0.25em;
    color: rgba(0,0,0,.4);
    font-size: 0.96em;
}

.ui.mobile.only.grid {
    position: relative;
}

.mobile.action.links {
    display: inline-flex;
    align-items: flex-start;

    .icon.link {
        font-size: 1.2rem;
        padding: 0.35rem;
        display: inline-block;

        &.plus, &.close {
            font-size: 2.25rem;
        }

        .icon {
            margin: 0;
        }
    }
}

.ui.action.menu {
    .ui.dropdown {
        .menu {
            > .active.item,
            > .selected.item {
                font-weight: 400 !important;
                color: rgba(0,0,0,.87)!important;

                &:not(:hover) {
                    background: none !important;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        min-height: 0;
    }
}

@media only screen and (max-width: 767px) {
    .ui.table.responsive.unstackable {
        tbody {
            tr {
                position: relative;

                td:first-child {
                    padding-right: 2em; //to give way for the action menu
                }

                .ui.action.menu {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    .list-container {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-bottom: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #fbf7f2;/*#fafff8;*/
        border-radius: 20px 20px 0 0;
    }
}
</style>